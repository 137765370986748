import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { TextField }from 'redux-form-material-ui'
import { munvoBlue } from '../utils/globalStyles'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {KEYWORD_LANGUAGE_LIST} from  '../utils/Constants'



class DefaultKeywordsEditForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // isRegex: false,
      parentComponent:this.props.parentComponent,
      initialized: false,
      // selectedWebhook: { value: "0", key: "0", label: "N/A" },
      webhookList: this.props.webhookList,
      selectedDefaultKeywords: this.props.initialValues ? this.props.initialValues : {role: 'stop', isRegex: false, keywords: '', response: '',webhook:{ value: null, key: null, label: "N/A" }},
      languageList : KEYWORD_LANGUAGE_LIST ,
      roleList : this.props.parentComponent === "DefaultKeywords" ? ["stop","start","help"] :["stop","start","help","global"] ,
    };

    this.toggleRegex = this.toggleRegex.bind(this);

    this.handleWebhookChange = this.handleWebhookChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.renderRegex = this.renderRegex.bind(this);


  }

  componentDidMount() {

    this.setState({
      webhookList: this.props.webhookList
    });
  }
  
  toggleRegex() {
    var tempSelectedDefaultKeywords = {...this.state.selectedDefaultKeywords}
    tempSelectedDefaultKeywords.isRegex = !this.state.selectedDefaultKeywords.isRegex;
    this.setState({selectedDefaultKeywords:tempSelectedDefaultKeywords})
  }


  handleLanguageChange = (event) => {
    var tempSelectedDefaultKeywords = {...this.state.selectedDefaultKeywords}
    tempSelectedDefaultKeywords.language = event.target.value;
    this.setState({selectedDefaultKeywords:tempSelectedDefaultKeywords})
  }

  handleRoleChange = (event) => {
    var tempSelectedDefaultKeywords = {...this.state.selectedDefaultKeywords}
    tempSelectedDefaultKeywords.role = event.target.value;
    this.setState({selectedDefaultKeywords:tempSelectedDefaultKeywords})
  }

  handleWebhookChange(event) {
    var tempSelectedWebhook;
    //default no webhook
    tempSelectedWebhook = { value: null, key: null, label: "N/A" }
    //find the webhook url with the right id

    for (var i = 0; i < this.props.webhookList.length; i++) {
      if (this.props.webhookList[i].id && this.props.webhookList[i].id == event.target.value) {
        tempSelectedWebhook = { value: event.target.value, key: event.target.value, label: this.props.webhookList[i].url }
        break;
      }
    }

    // this.setState({ selectedWebhook: tempSelectedWebhook })

    var tempSelectedDefaultKeywords = {...this.state.selectedDefaultKeywords}
    tempSelectedDefaultKeywords.webhookId = tempSelectedWebhook.id;
    tempSelectedDefaultKeywords.selectedWebhook = tempSelectedWebhook;

    this.setState({selectedDefaultKeywords:tempSelectedDefaultKeywords})
  }

  renderRegex = () =>{
    if(this.props.parentComponent !== "DefaultKeywords"){
      return <Field
      defaultValue = {false}
      name="isRegex"
      onChange={this.toggleRegex}
      parse={value => (value ? "true" : "false")}
      component={RegexField}

    />
    }
    else{
      return
    }
  }
  

  render(){
    return(
      <form>
        <Grid container>
          <Grid item xs={12}>
            <Field 
              value = {this.state.selectedDefaultKeywords.keywords}
              component={TextField} 
              type='text' 
              floatingLabelText='Keyword list (comma separated)' 
              name='keywords' 
              readOnly={false}
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field 
              value = {this.state.selectedDefaultKeywords.response}
              component={TextField} 
              type='text' 
              floatingLabelText='SMS reply' 
              name='response'
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
          <br/>
          {this.renderRegex()}
          </Grid>
          <Grid item xs={12}>
          <label>Role &nbsp;</label><br/>
          <Field
            defaultValue="stop"
            name="role"
            component="select"
            value={this.state.selectedDefaultKeywords.role}
            onChange={this.handleRoleChange}
          >
            {this.state.roleList.map((option,index) => (
              <option key={index} value={option} label={option} >{option}</option>
            ))}
          </Field>
          </Grid>
          <Grid item xs={12}>
          <label>Language &nbsp;</label><br/>
          <Field
            defaultValue = "EN"
            name="language"
            component="select"
            value={this.state.selectedDefaultKeywords.language}
            onChange={this.handleLanguageChange}

          >
            {this.state.languageList.map((option,index) => (
              <option key={index} value={option} label={option} >{option}</option>
            ))}
          </Field>
          </Grid>
          <Grid item xs={12}>

          <label>Webhook &nbsp;</label><br/>
          <Field
            defaultValue = {null}
            name="webhookId"
            component="select"
            value={this.state.selectedDefaultKeywords.webhook ?this.state.selectedDefaultKeywords.webhook.id : null}
            onChange={this.handleWebhookChange}

          >
            <option value={null} label="None"/>
            {this.props.webhookList.map(option => (
            <option key={option.id} value={option.id} label={option.url + " (" + option.contentType.toUpperCase() + ")"} />
            ))}
          </Field>
          </Grid>

        </Grid>
      </form>
    )
  }
}

const RegexField = ({ input}) => (
  <FormControlLabel
    control={
      <Switch
        label={"Regex Toggle"}
        checked={input.value==="true" ? true : false}
        value={input.value ? "true" : "false"}
        onChange={input.onChange}
        color="primary"
      />
    }
    label="Use Regular Expression instead of comma separated list"
  />
);

DefaultKeywordsEditForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

const validate = (values, props) => {
  const errors = {}
  if(!values.keywords)
  {
    errors.keywords = "Required"
  }
  if(!values.response)
  {errors.response = "Required"}

  if(!values.isRegex){
    values.isRegex = false
  }
  

  if(!values.role){
    values.role = "stop"
  }
  if(!values.language){
    values.language = "EN"
  }
  if(!values.webhookId){
    values.webhookId = null
  }
  return errors

}

DefaultKeywordsEditForm = reduxForm({
  form: 'DefaultKeywordsEditForm',
  validate,
  onSubmit: (values, dispatch, props) => { props.submitCallback(values) }
})(DefaultKeywordsEditForm)

export default DefaultKeywordsEditForm;